import React, {useState} from "react"

import {EquipmentCategoriesDDList} from "../../EquipmentCategories/components/EquipmentCategoriesDDList"

import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"
import TextInput from "../../../toolkits/TextInput/TextInput"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"

import {ERROR_CODE, SUCCESS_CODE} from "../../../constants/other"
import {useCreateEquipmentMutation, useUpdateEquipmentMutation} from "../../../api/equipment"


const CreateUpdateEquipmentPopup = ({equipment, refreshTable, setUserMessage, close}) => {
    const [createEquipment] = useCreateEquipmentMutation()
    const [updateEquipment] = useUpdateEquipmentMutation()

    const [name, setName] = useState(equipment?.name)
    const [category, setCategory] = useState(equipment?.category ? {value: equipment.category.id, label: equipment.category.name} : null)
    const [serialNumber, setSerialNumber] = useState(equipment?.serial_number)

    const handleSubmit = () => {
        const data = {
            name: name,
            category_id: category.value,
            serial_number: serialNumber?.trim() ? serialNumber?.trim() : null,
        }

        if (equipment) {
            updateEquipment({id: equipment.id, data: data})
                .unwrap()
                .then(() => {
                    refreshTable()
                    close()
                    setUserMessage({message: "Equipment was successfully updated!", code: SUCCESS_CODE})
                })
                .catch(error => {
                    close()
                    setUserMessage({message: error.message, code: ERROR_CODE})
                })
        } else {
            createEquipment(data)
                .unwrap()
                .then(() => {
                    refreshTable()
                    close()
                    setUserMessage({message: "Equipment was successfully created!", code: SUCCESS_CODE})
                })
                .catch(error => {
                    close()
                    setUserMessage({message: error.message, code: ERROR_CODE})
                })
        }
    }

    return (
        <CustomPopup
            className="create-update-equipment-popup"
            text_title={`${equipment ? "Edit" : "Create"} Equipment`}
        >
            <div className="create-update-equipment-popup-content">
                <TextInput
                    text_label="Equipment"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <EquipmentCategoriesDDList
                    value={category ? {value: category.id, label: category.label} : null}
                    setCategory={setCategory}
                    setUserMessage={setUserMessage}
                />
                <TextInput
                    text_label="Number"
                    value={serialNumber}
                    onChange={(e) => setSerialNumber(e.target.value)}
                />
                <div className="popup-bottom">
                    <div className="popup-bottom-button-container">
                        <SmallButton btnType="secondary" onClick={close}> CANCEL </SmallButton>
                        <SmallButton
                            type="submit"
                            onClick={handleSubmit}
                            disabled={!name || !category}
                        >{equipment ? "SAVE" : "CREATE"}</SmallButton>
                    </div>
                </div>
            </div>
        </CustomPopup>
    )
}

export default CreateUpdateEquipmentPopup
