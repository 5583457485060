import React from "react"
import Popup from "reactjs-popup"

import Protected from "../../../components/Protected/Protected"
import InfoPopup from "../../../components/InfoPopup"
import DeletePositionPopup from "./DeletePositionPopup"
import CreateUpdatePositionPopup from "./CreateUpdatePositionPopup"

import CustomScrollableTable from "../../../toolkits/CustomScrollableTable"
import {EmptyResult} from "../../../toolkits/EmptyResult/EmptyResult"


import {PERMISSIONS} from "../../../constants/permissions"


export const EmployeePositionsTable = ({employeePositions, onSubmit, setUserMessage, empty}) => {
    
    const positionsList = employeePositions.map((employeePosition, i) => {
        const popupParams = {
            position: employeePosition,
            onSubmit: onSubmit,
            setUserMessage: setUserMessage,
        }
        const actions = (
            <>
                <Popup trigger={<span className="custom-scrollable-table-link"> edit </span>} modal>
                    {close => (
                        <CreateUpdatePositionPopup {...popupParams} close={close}/>
                    )}
                </Popup>
                <Popup trigger={<span className="custom-scrollable-table-link"> delete </span>} modal>
                    {close => (
                        <DeletePositionPopup {...popupParams} close={close}/>
                    )}
                </Popup>
            </>
        )

        return (
            <tr key={`${i} ${employeePosition.id}`} className="custom-scrollable-table-row employee-positions-content-table-row">
                <td>
                    <Popup trigger={
                        <span className="cell-limited-text employee-positions-content-table-first-column t-s1 clickable">{employeePosition.name}</span>
                    } modal>
                        {close => (
                            <InfoPopup
                                title="Position"
                                value={employeePosition.name}
                                close={close}
                            />
                        )}
                    </Popup>
                </td>
                <td>
                    <Protected permissions={[PERMISSIONS.WRITE_EMPLOYEE_POSITION]}>
                        {actions}
                    </Protected>
                </td>
            </tr>
        )
    })
    
    return (
        <CustomScrollableTable>
            <table className="employee-positions-content-table">
                <thead>
                    <tr>
                        <th><span className="custom-scrollable-table-head-span">POSITION</span></th>
                        <th><span className="custom-scrollable-table-head-span">ACTIONS</span></th>
                    </tr>
                </thead>
                <tbody>
                    {positionsList}
                    {empty && <div className="employee-positions-content-empty-result"><EmptyResult/></div>}
                </tbody>
            </table>    
        </CustomScrollableTable>
    )
}

export default EmployeePositionsTable
