import React, {useState, useEffect} from "react"
import {ERROR_CODE, PRODUCT_VARIANT_STATUS, SUCCESS_CODE, PRODUCT_OPTION_TYPE} from "../../../constants/other"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"
import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"
import {useUpdateProductVariantsMutation} from "../../../api/products"
import DDList from "../../../toolkits/DDList/DDList"
import TextInput from "../../../toolkits/TextInput/TextInput"
import {getColorOptionValues} from "../../../utils/other.utils"

const EditVariantsPopup = ({product, refreshTable, setUserMessage, close}) => {
    const [updateProductVariants] = useUpdateProductVariantsMutation()

    const options = product.options?.sort((a, b) => a.position - b.position)
    const optionsTitles = options.map(option => option.name)
    const isSimpleProduct = product.options.length === 0
    const [variants, setVariants] = useState([
        {
            id: null,
            option1:  "",
            option2: "",
            option3: "",
            option4: "",
            option5: "",
            status: PRODUCT_VARIANT_STATUS.IN_STOCK,
        }
    ])

    useEffect(() => {
        setVariants(product.variants
            .map((variant) => ({
                id: variant.id ? variant.id : null,
                option1:  variant.option1,
                option2:  variant.option2,
                option3:  variant.option3,
                option4:  variant.option4,
                option5:  variant.option5,
                status: variant.status,
            })) || variants
        )
    }, [product])

    const variantsList = variants?.map((variant, i) => {
        const getOptionValue = (value, type) => type === PRODUCT_OPTION_TYPE.COLOR ? getColorOptionValues(value).text : value
        const variantOptionValues = [
            getOptionValue(variant.option1, options[0]?.type),
            getOptionValue(variant.option2, options[1]?.type),
            getOptionValue(variant.option3, options[2]?.type),
            getOptionValue(variant.option4, options[3]?.type),
            getOptionValue(variant.option5, options[4]?.type),
        ]
        const notExistVariant = variant.status === PRODUCT_VARIANT_STATUS.NOT_EXIST

        const handleChangeVariant = (variant_id, newStatus) => {
            setVariants(prevVariants =>
                prevVariants.map(v => v.id === variant_id ? {...v, status: newStatus.value} : v)
            )
        }

        return (
            <tr key={`variant_${i}`} className="products-content-table-last-column-inventory-popup-content-table-row">
                <td>
                    <div className="products-content-table-last-column-inventory-popup-content-table-status">
                        <DDList
                            listOptions={Object.values(PRODUCT_VARIANT_STATUS)
                                .filter(status =>
                                    status !== PRODUCT_VARIANT_STATUS.DELETED &&
                                    (status !== PRODUCT_VARIANT_STATUS.NOT_EXIST || !isSimpleProduct)
                                )
                                .map((status) => ({value: status, label: status}))
                            }
                            value={{value: variant.status, label: variant.status}}
                            onChange={(status) => handleChangeVariant(variant.id, status)}
                            isSearchable={false}
                        />
                    </div>
                </td>
                {isSimpleProduct ?
                    <td key={`variant_name_${i}`}>
                        <div className="products-content-table-last-column-inventory-popup-content-table-name">
                            <TextInput defaultValue={product.name} disabled/>
                        </div>
                    </td> :
                    variantOptionValues
                        .filter(value => value !== null)
                        .map((value, j) => (
                            <td key={`variant_option_${j}`}>
                                <div
                                    className={`products-content-table-last-column-inventory-popup-content-table-option ${notExistVariant ? "not-exist" : ""}`}>
                                    <TextInput
                                        defaultValue={value}
                                        disabled
                                    />
                                </div>
                            </td>
                        ))
                }
            </tr>
        )
    })

    const handleSubmit = () => {
        updateProductVariants({productId: product.id, data: variants})
            .then(() => {
                refreshTable()
                setUserMessage({message: "Product variants were successfully updated!", code: SUCCESS_CODE})
            })
            .catch(error => {
                setUserMessage({message: error.response.data.message, code: ERROR_CODE})
            })
    }

    return (
        <CustomPopup text_title={`Inventory of ${product.name}`} wrapperClass="products-content-table-last-column-inventory-popup-wrapper">
            <div className="products-content-table-last-column-inventory-popup-content">
                <table className="products-content-table-last-column-inventory-popup-content-table">
                    <thead>
                        <tr>
                            <th><span className="custom-scrollable-table-head-span head-status">STATUS</span></th>
                            {isSimpleProduct ?
                                <th><span className="custom-scrollable-table-head-span head-option">Name</span></th> :
                                optionsTitles.map((title, i) => (
                                    title &&
                                    <th key={`option_title_${i}`}>
                                        <span className="custom-scrollable-table-head-span head-option">
                                            {title}
                                        </span>
                                    </th>
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {variantsList}
                    </tbody>
                </table>
                <div className="popup-bottom-button">
                    <div className="popup-bottom-button-container">
                        <SmallButton btnType="secondary" onClick={close}> CANCEL </SmallButton>
                        <SmallButton onClick={handleSubmit}> SAVE </SmallButton>
                    </div>
                </div>
            </div>
        </CustomPopup>
    )
}

export default EditVariantsPopup