import React from "react"
import Popup from "reactjs-popup"

import Protected from "../../../components/Protected/Protected"
import AssignEquipmentPopup from "./AssignEquipmentPopup"
import DeleteEquipmentPopup from "./DeleteEquipmentPopup"
import CreateUpdateEquipmentPopup from "./CreateUpdateEquipmentPopup"

import CustomScrollableTable from "../../../toolkits/CustomScrollableTable"
import {EmptyResult} from "../../../toolkits/EmptyResult/EmptyResult"
import InfoPopup from "../../../components/InfoPopup"

import {ORDERING_KEYS} from "../const"

import ArrowDown from "@mui/icons-material/KeyboardArrowDown"
import {PERMISSIONS} from "../../../constants/permissions"


export const EquipmentTable = ({
    equipment,
    ordering,
    setOrdering,
    refreshTable,
    setUserMessage,
    empty,
}) => {
    
    const equipmentList = equipment.map((eq, i) => {
        const popupProps = {
            equipment: eq,
            setUserMessage: setUserMessage,
            refreshTable: refreshTable,
        }

        const equipmentActions = (
            <>
                <Protected permissions={[PERMISSIONS.WRITE_EQUIPMENT]}>
                    <Popup trigger={<span className="equipment-content-table-link">{eq.user ? "unassign" : "assign"}</span>} modal>
                        {close => (
                            eq.user ? <AssignEquipmentPopup assign={false} {...popupProps} close={close}/> :
                                <AssignEquipmentPopup assign={true} {...popupProps} close={close}/>
                        )}
                    </Popup>
                
                    <Popup trigger={<span className="equipment-content-table-link"> edit </span>} modal>
                        {close => (
                            <CreateUpdateEquipmentPopup editFlag={true} {...popupProps} close={close}/>
                        )}
                    </Popup>

                    <Popup trigger={<span className="equipment-content-table-link"> delete </span>} modal>
                        {close => (
                            <DeleteEquipmentPopup equipment={eq} {...popupProps} close={close}/>
                        )}
                    </Popup>
                </Protected>
            </>
        )

        return (
            <tr key={`${i} ${eq.id}`} className="custom-scrollable-table-row">
                <td>
                    <Popup trigger={
                        <span className="cell-limited-text clickable"> {eq.name} </span>} modal>
                        {close => (
                            <InfoPopup
                                title="Equipment"
                                value={eq.name}
                                close={close}
                            />
                        )}
                    </Popup>
                </td>
                <td>
                    {eq.category?.name &&
                        <Popup trigger={
                            <span className="cell-limited-text clickable"> {eq.category?.name} </span>} modal>
                            {close => (
                                <InfoPopup
                                    title="Category"
                                    value={eq.category?.name}
                                    close={close}
                                />
                            )}
                        </Popup>
                    }
                </td>
                <td>
                    {eq.serial_number &&
                        <Popup trigger={
                            <span className="cell-limited-text clickable"> {eq.serial_number} </span>} modal>
                            {close => (
                                <InfoPopup
                                    title="Serial Number"
                                    value={eq.serial_number}
                                    close={close}
                                />
                            )}
                        </Popup>
                    }
                </td>
                <td>{eq.user ? `${eq.user.first_name} ${eq.user.last_name}` : "No user"}</td>
                <td>
                    {equipmentActions}
                </td>
            </tr>
        )
    })
    
    return (
        <CustomScrollableTable>
            <table className="equipment-content-table">
                <thead>
                    <tr>
                        <th>
                            <span className="custom-scrollable-table-head-span-first">EQUIPMENT</span>
                            <ArrowDown
                                className={`equipment-content-arrow ${
                                    ordering.key === ORDERING_KEYS.EQUIPMENT
                                        ? !ordering.asc
                                            ? "rotate-arrow active-arrow "
                                            : "active-arrow"
                                        : ""}`}
                                onClick={() => setOrdering({
                                    key: ORDERING_KEYS.EQUIPMENT,
                                    asc: ordering.key === ORDERING_KEYS.EQUIPMENT ? !ordering.asc : true,
                                })}
                            />
                        </th>
                        <th>
                            <span className="custom-scrollable-table-head-span">CATEGORY</span>
                            <ArrowDown
                                className={`equipment-content-arrow ${
                                    ordering.key === ORDERING_KEYS.CATEGORY
                                        ? !ordering.asc
                                            ? "rotate-arrow active-arrow "
                                            : "active-arrow"
                                        : ""}`}
                                onClick={() => setOrdering({
                                    key: ORDERING_KEYS.CATEGORY,
                                    asc: ordering.key === ORDERING_KEYS.CATEGORY ? !ordering.asc : true,
                                })}
                            />
                        </th>
                        <th><span className="custom-scrollable-table-head-span">SERIAL NUMBER</span></th>
                        <th>
                            <span className="custom-scrollable-table-head-span">USER</span>
                            <ArrowDown
                                className={`equipment-content-arrow ${
                                    ordering.key === ORDERING_KEYS.USER
                                        ? !ordering.asc
                                            ? "rotate-arrow active-arrow "
                                            : "active-arrow"
                                        : ""}`}
                                onClick={() => setOrdering({
                                    key: ORDERING_KEYS.USER,
                                    asc: ordering.key === ORDERING_KEYS.USER ? !ordering.asc : true,
                                })}
                            />
                        </th>    
                        <th><span className="custom-scrollable-table-head-span">ACTIONS</span></th>
                    </tr>
                </thead>
                <tbody>
                    {equipmentList}
                    {empty && <EmptyResult/>}
                </tbody>
            </table>
        </CustomScrollableTable>
    )
}

export default EquipmentTable
