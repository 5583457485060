import React, {useState} from "react"
import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"
import TextInput from "../../../toolkits/TextInput/TextInput"
import {COUPON_ERROR_MESSAGES} from "../../../constants/other"
import NewcoinsReceivedPopup from "../../../components/Greetings/Newcoins/NewcoinsReceivedPopup"
import FlexiblePopup from "../../../toolkits/FlexiblePopup"
import Popup from "reactjs-popup"
import {SETTINGS} from "../../../constants/other"
import SettingsEnabled from "../../../components/SettingsEnabled/SettingsEnabled"
import {PERMISSIONS} from "../../../constants/permissions"
import Protected from "../../../components/Protected/Protected"
import {useClaimCouponMutation} from "../../../api/coupons"


const ClaimCoupon = () => {
    const [claimCoupon, { isLoading }] = useClaimCouponMutation()
    const [code, setCode] = useState("")
    const [coupon, setCoupon] = useState(null)
    const [userMessage, setUserMessage] = useState(null)

    const [showClaimPopup, setShowClaimPopup] = useState(false)
    const [showGreetingPopup, setShowGreetingPopup] = useState(false)
    const [showErrorPopup, setShowErrorPopup] = useState(false)

    const handleSubmit = () => {

        claimCoupon({code: code})
            .unwrap()
            .then((response) => {
                setCoupon(response)
                setShowClaimPopup(false)
                setShowGreetingPopup(true)
            })
            .catch((error) => {
                setUserMessage(COUPON_ERROR_MESSAGES[error.message] || "Something went wrong!")
                setShowClaimPopup(false)
                setShowErrorPopup(true)
            })
            .finally(() => setCode(""))
    }

    return (
        <>
            <SettingsEnabled settingsNames={[SETTINGS.NEWCOINS_ENABLED]}>
                <Protected permissions={[PERMISSIONS.READ_OWN_NEWCOINS]}>
                    <div className="shop-header-banner-left-claim-coupon">
                        <SmallButton onClick={() => setShowClaimPopup(true)}>
                            Claim coupon
                        </SmallButton>
                    </div>
                </Protected>
            </SettingsEnabled>

            <Popup open={showClaimPopup} onClose={() => setShowClaimPopup(false)} closeOnDocumentClick={false} modal>
                {close => 
                    (
                        <CustomPopup text_title="Claim Coupon">
                            <TextInput
                                text_label="Coupon code"
                                placeholder='E.g. "OMW-432"'
                                onChange={(e) => setCode(e.target.value)} />
                            <div className="popup-bottom">
                                <div className="popup-bottom-button-container">
                                    <SmallButton
                                        btnType="secondary"
                                        disabled={isLoading}
                                        onClick={() => {
                                            setCode("")
                                            close()
                                        }}
                                    >CANCEL </SmallButton>
                                    <SmallButton
                                        type="submit"
                                        onClick={() => handleSubmit()}
                                        loading={isLoading}
                                        disabled={!code}
                                    > CLAIM </SmallButton>
                                </div>
                            </div>
                        </CustomPopup>
                    )}
            </Popup>

            <Popup open={showErrorPopup} onClose={() => setShowErrorPopup(false)} closeOnDocumentClick={false} modal>
                {close => 
                    (
                        <CustomPopup text_title="Oops...">
                            <div className="shop-header-banner-left-claim-coupon-error-message t-s4">
                                {userMessage}
                            </div>
                            <div className="popup-bottom">
                                <div className="popup-bottom-button-container">
                                    <SmallButton btnType="secondary" onClick={close}> OK </SmallButton>
                                </div>
                            </div>
                        </CustomPopup>
                    )}
            </Popup>

            <Popup open={showGreetingPopup} onClose={() => setShowGreetingPopup(false)}  closeOnDocumentClick={false} modal>
                {close => 
                    (
                        <FlexiblePopup noStyle={true}>
                            <div className="shop-header-banner-left-claim-coupon-success-wrapper">
                                <NewcoinsReceivedPopup
                                    amount={coupon?.coins_reward}
                                    reason={coupon?.message}
                                    buttonText="SHOP NOW"
                                    close={close}
                                />
                            </div> 
                        </FlexiblePopup>
                    )
                }
            </Popup>
        </>
    )
}

export default ClaimCoupon
