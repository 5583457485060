import "./index.scss"

import React, {useEffect, useState} from "react"
import {Helmet} from "react-helmet"
import Popup from "reactjs-popup"
import InfiniteScroll from "react-infinite-scroll-component"

import Protected from "../../components/Protected/Protected"
import CouponsTable from "./components/CouponsTable"
import CreateUpdateCouponPopup from "./components/CreateUpdateCouponPopup"

import SearchForm from "../../toolkits/SearchForm/SearchForm"
import SmallButton from "../../toolkits/SmallButton/SmallButton"
import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"

import {COUPON_STATUSES} from "../../constants/other"
import {PERMISSIONS} from "../../constants/permissions"
import FilterPanel from "../../toolkits/FilterPanel"

import {usePaginatedQuery} from "../../hooks/common/usePaginatedQuery"
import {useGetCouponsQuery} from "../../api/coupons"
import CircularProgress from "../../toolkits/CircularProgress/CircularProgress"


const Coupons = () => {
    const [addNewCouponPopup, setAddNewCouponPopup] = useState(false)
    const [userMessage, setUserMessage] = useState(null)
    const [searchKey, setSearchKey] = useState()
    const couponStatuses = ["All", COUPON_STATUSES.ACTIVE.label, COUPON_STATUSES.CLAIMED.label, COUPON_STATUSES.DEACTIVATED.label]
    const [statusFilter, setStatusFilter] = useState(couponStatuses[0])

    const {items, loadNextPage, hasMore, changeParams, reset, empty} = usePaginatedQuery(
        useGetCouponsQuery,
        {
            defaultState: {
                params: {
                    statuses: statusFilter === "All" ? undefined : statusFilter.toUpperCase(),
                    query: searchKey,
                }
            }
        }
    )

    useEffect(() => changeParams({
        statuses: statusFilter === "All" ? undefined : statusFilter.toUpperCase(),
        query: searchKey,
    }, false), [statusFilter, searchKey])

    return (
        <>
            <Helmet>
                <title>Coupons - Newsoft Inside</title>
            </Helmet>

            <div className="coupons">
                <div>
                    {userMessage &&
                        <AlertMessage setMessage={setUserMessage} message={userMessage.message} code={userMessage.code}/>
                    }
                </div>
                <div className="coupons-header">
                    <SearchForm onChange={setSearchKey} debounceMs={500} />
                    <Protected permissions={[PERMISSIONS.WRITE_COUPONS]}>
                        <div className="coupons-header-button">
                            <SmallButton onClick={() => setAddNewCouponPopup(true)}> ADD NEW COUPON </SmallButton>
                        </div>
                    </Protected>
                </div>
                <div className="coupons-filter">
                    <FilterPanel
                        title={"Type"}
                        options={couponStatuses}
                        selectedOption={statusFilter}
                        setOptionFilter={setStatusFilter}
                    />
                </div>
                <InfiniteScroll
                    pageStart={0}
                    next={loadNextPage}
                    hasMore={hasMore}
                    loader={<CircularProgress key="circular-progress"/>}
                    dataLength={items.length}
                >
                    <div className="coupons-content">
                        <CouponsTable
                            coupons={items}
                            refreshTable={reset}
                            setUserMessage={setUserMessage}
                            empty={empty}
                        />
                    </div>
                </InfiniteScroll>
            </div>

            <Popup open={addNewCouponPopup} closeOnDocumentClick={false} onClose={() => setAddNewCouponPopup(false)} modal>
                {close => (
                    <CreateUpdateCouponPopup
                        refreshTable={reset}
                        setUserMessage={setUserMessage}
                        close={close}
                    />
                )}
            </Popup>

        </>)
}

export default Coupons
