import "./index.scss"

import React, {useEffect} from "react"
import {Helmet} from "react-helmet"
import {useDispatch, useSelector} from "react-redux"

import {receiveNotifications} from "../Notifications/actions/dashboardNotificationsActions"

import DashboardCard from "./components/DashboardCard"

import {isAdmin} from "../../utils/permission.utils"
import {isNotZero} from "../../utils/other.utils"

import {
    ClientsRoute,
    EmployeePositionsRoute,
    EmployeesRoute,
    EquipmentCategoriesRoute,
    EquipmentRoute,
    PerformanceReviewsRoute,
    ProjectsRoute,
    UserReportsRoute,
    RolesRoute,
    TimeRecordRequestsRoute,
    TimeOffBalancesRoute,
    TimeOffRequestsRoute,
    PaymentReportsRoute,
    SettingsRoute,
    OnboardingRequestsRoute,
    NewcoinsBalancesRoute,
    ProductsRoute,
    PurchaseRequestsRoute,
    CouponsRoute,
    AuditLogsRoute,
    GoogleIntegrationRoute,
    PublicHolidayCalendarsRoute,
} from "../../routes"
import {PERMISSIONS} from "../../constants/permissions"
import {DESKTOP_WIDTH, SETTINGS} from "../../constants/other"
import {useMediaQuery} from "react-responsive"


const Dashboard = () => {
    const dispatch = useDispatch()
    const isDesktop = useMediaQuery({minWidth: DESKTOP_WIDTH})

    const {
        performanceReviewCount,
        timeOffRequestsCount,
        timeRecordRequestsCount,
        onboardingRequestsCount,
        purchaseRequestsCount,
    } = useSelector((state) => state.dashboardNotifications)
    
    useEffect(() => {
        dispatch(receiveNotifications())
    }, [])

    return (
        <>
            <Helmet>
                <title>Dashboard - Newsoft Inside</title>
            </Helmet>
            <div className={`dashboard ${isAdmin() ? "dashboard-admin" : ""}`}>
                <div className="dashboard-box">
                    {isAdmin() ?
                        <DashboardCard title={EmployeesRoute.name} link={EmployeesRoute.path}
                            permissions={[PERMISSIONS.READ_USER]}/>
                        :
                        <>
                            <DashboardCard title={UserReportsRoute.name} link={UserReportsRoute.path}
                                permissions={[PERMISSIONS.READ_REPORTS]}/>

                            <DashboardCard title={PaymentReportsRoute.name} link={PaymentReportsRoute.path}
                                permissions={[PERMISSIONS.READ_PAYMENT_REPORTS]}
                            /> 

                            <DashboardCard title={ClientsRoute.name} link={ClientsRoute.path}
                                permissions={[PERMISSIONS.READ_CLIENT]}/>

                            <DashboardCard title={ProjectsRoute.name} link={ProjectsRoute.path}
                                permissions={[PERMISSIONS.READ_PROJECT]}/>

                            <DashboardCard
                                title={PerformanceReviewsRoute.name}
                                link={PerformanceReviewsRoute.path}
                                permissions={[PERMISSIONS.READ_PERFORMANCE_REVIEW]}
                                notification={isNotZero(performanceReviewCount)}
                            />

                            <DashboardCard
                                title={TimeOffRequestsRoute.name}
                                link={TimeOffRequestsRoute.path}
                                permissions={[PERMISSIONS.READ_TIME_OFF_REQUEST]}
                                notification={isNotZero(timeOffRequestsCount)}
                            />

                            <DashboardCard
                                title={TimeRecordRequestsRoute.name}
                                link={TimeRecordRequestsRoute.path}
                                permissions={[PERMISSIONS.READ_TIME_RECORD]}
                                notification={isNotZero(timeRecordRequestsCount)}
                            />

                            <DashboardCard
                                title={OnboardingRequestsRoute.name}
                                link={OnboardingRequestsRoute.path}
                                permissions={[PERMISSIONS.READ_ONBOARDING]}
                                notification={isNotZero(onboardingRequestsCount)}
                            />

                            <DashboardCard
                                title={PurchaseRequestsRoute.name}
                                link={PurchaseRequestsRoute.path}
                                permissions={[PERMISSIONS.READ_PURCHASE_REQUEST]}
                                notification={isNotZero(purchaseRequestsCount)}
                                settingsNames={[SETTINGS.SHOP_ENABLED]}
                            />

                            <DashboardCard title={TimeOffBalancesRoute.name} link={TimeOffBalancesRoute.path}
                                permissions={[PERMISSIONS.READ_TIME_OFF_BALANCE]}/>

                            <DashboardCard title={NewcoinsBalancesRoute.name} link={NewcoinsBalancesRoute.path}
                                permissions={[PERMISSIONS.READ_NEWCOINS]} settingsNames={[SETTINGS.NEWCOINS_ENABLED]}/>

                            <DashboardCard title={CouponsRoute.name} link={CouponsRoute.path}
                                permissions={[PERMISSIONS.READ_COUPONS]} settingsNames={[SETTINGS.NEWCOINS_ENABLED]}/>

                            <DashboardCard title={ProductsRoute.name} link={ProductsRoute.path}
                                permissions={[PERMISSIONS.WRITE_SHOP]} settingsNames={[SETTINGS.SHOP_ENABLED]}/>

                            <DashboardCard title={EquipmentRoute.name} link={EquipmentRoute.path}
                                permissions={[PERMISSIONS.READ_EQUIPMENT]}/>
                            
                            <DashboardCard title={EquipmentCategoriesRoute.name} link={EquipmentCategoriesRoute.path}
                                permissions={[PERMISSIONS.READ_EQUIPMENT]}/>

                            <DashboardCard title={RolesRoute.name} link={RolesRoute.path}
                                permissions={[PERMISSIONS.READ_ROLE]}/>

                            <DashboardCard title={EmployeePositionsRoute.name} link={EmployeePositionsRoute.path}
                                permissions={[PERMISSIONS.READ_EMPLOYEE_POSITION]}/>

                            <DashboardCard
                                title={GoogleIntegrationRoute.name}
                                link={GoogleIntegrationRoute.path}
                                permissions={[PERMISSIONS.READ_INTEGRATIONS]}
                            />

                            {isDesktop &&
                                <DashboardCard title={PublicHolidayCalendarsRoute.name} link={PublicHolidayCalendarsRoute.path}
                                    permissions={[PERMISSIONS.READ_CALENDAR_EVENTS]}/>
                            }

                            <DashboardCard title="Global Settings" link={SettingsRoute.path}
                                permissions={[PERMISSIONS.WRITE_SETTINGS]}/>

                            {isDesktop &&
                                <DashboardCard title={AuditLogsRoute.name} link={AuditLogsRoute.path}
                                    permissions={[PERMISSIONS.READ_AUDIT_LOGS]}/>
                            }

                        </>
                    }
                </div>
            </div>
        </>
    )
}

export default Dashboard
