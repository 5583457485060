import React, {useEffect, useMemo} from "react"
import {Helmet} from "react-helmet"
import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"
import SearchForm from "../../toolkits/SearchForm/SearchForm"
import InfiniteScroll from "react-infinite-scroll-component"
import StyledCircularProgress from "../../toolkits/CircularProgress/CircularProgress"
import {useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {DESKTOP_WIDTH, PRODUCT_STATUS, PRODUCTS_SORT_OPTIONS} from "../../constants/other"
import "./index.scss"
import ProductsTable from "./components/ProductsTable"
import SmallButton from "../../toolkits/SmallButton/SmallButton"
import CustomLink from "../../toolkits/CustomLink"
import {CreateProductRoute} from "../../routes"
import {useMediaQuery} from "react-responsive"
import FilterPanel from "../../toolkits/FilterPanel"
import MultipleSelect from "../../toolkits/Multiselect"
import {receiveProductCategories} from "../CreateProduct/actions/createProductActions"
import DDFilter from "../../toolkits/DDFilter"
import {usePaginatedQuery} from "../../hooks/common/usePaginatedQuery"
import {useGetProductsQuery} from "../../api/products"

const Products = () => {
    const dispatch = useDispatch()
    const [sortBy, setSortBy] = useState(PRODUCTS_SORT_OPTIONS.NEWEST_FIRST)

    const [searchKey, setSearchKey] = useState()
    const [userMessage, setUserMessage] = useState(null)
    const [statusFilter, setStatusFilter] = useState(PRODUCT_STATUS.ALL)
    const {categories} = useSelector((state) => state.createProduct)
    const [selectedCategoriesIds, setSelectedCategoriesIds] = useState([])
    const isDesktop = useMediaQuery({minWidth: DESKTOP_WIDTH})

    useEffect(() => {
        dispatch(receiveProductCategories())
    }, [])

    const params = useMemo(() => ({
        name: searchKey,
        status: statusFilter === PRODUCT_STATUS.ALL ? null : statusFilter,
        category_ids: selectedCategoriesIds.length > 0 ? selectedCategoriesIds.join(",") : null,
        order_by: sortBy.key,
    }), [searchKey, statusFilter, selectedCategoriesIds, sortBy])

    const { loadNextPage, items, loading, hasMore, reset, empty, changeParams } = usePaginatedQuery(useGetProductsQuery, { defaultState: { params } })

    useEffect(() => {
        changeParams(params)
    }, [params])

    return (
        <>
            <Helmet>
                <title> Products - Newsoft Inside </title>
            </Helmet>
            <div className="products">
                {userMessage && <AlertMessage setMessage={setUserMessage} message={userMessage.message} code={userMessage.code}/>}
                <div className="products-header">
                    <div className="products-header-search">
                        <SearchForm onChange={setSearchKey} debounceMs={500} />
                        {isDesktop &&
                        <CustomLink to={{
                            pathname: CreateProductRoute.path,
                        }}>
                            <SmallButton>New Product</SmallButton>
                        </CustomLink>}
                    </div>
                    <div className="products-header-filters">
                        <FilterPanel
                            title={"Status"}
                            options={PRODUCT_STATUS}
                            selectedOption={statusFilter}
                            setOptionFilter={setStatusFilter}
                        />
                        <div className="products-header-filters-category">
                            <span className="t-s4 products-header-filters-category-title">Category:</span>
                            <MultipleSelect
                                loading={loading}
                                placeholder={"All"}
                                options={categories}
                                selectedOptionIds={selectedCategoriesIds}
                                setSelectedOptionIds={setSelectedCategoriesIds}
                            />
                        </div>
                        <div className="products-header-filters-sort">
                            <DDFilter
                                title={"Sort by"}
                                defaultValue={{label: sortBy.label, value: sortBy.key}}
                                options={(Object.values(PRODUCTS_SORT_OPTIONS)).map((o) => ({label: o.label, value: o.key}))}
                                onChange={({ label, value }) => setSortBy({label, key: value})}
                            />
                        </div>
                    </div>
                </div>
                <InfiniteScroll
                    pageStart={0}
                    next={loadNextPage}
                    hasMore={hasMore}
                    loader={<StyledCircularProgress/>}
                    dataLength={items.length}
                >
                    <div className="products-content">
                        <ProductsTable
                            products={items}
                            setUserMessage={setUserMessage}
                            refreshTable={reset}
                            isDesktop={isDesktop}
                            empty={empty}
                        />
                    </div>
                </InfiniteScroll>
            </div>
        </>
    )
}

export default Products