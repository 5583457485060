import Protected from "../../../../components/Protected/Protected"
import MenuItem from "@material-ui/core/MenuItem"
import React from "react"
import {PERMISSIONS} from "../../../../constants/permissions"
import KebabIcon from "@mui/icons-material/MoreVert"
import Menu from "@material-ui/core/Menu"

export const CustomMenuItem = ({label, isShowItem, permissions, onClick, accessForAdmin}) => {

    const ItemWrapper = ({children}) => (permissions ?
        <Protected permissions={permissions} accessForAdmin={accessForAdmin}>
            {children}
        </Protected> : <>{children}</>
    )

    return (isShowItem || isShowItem === undefined) && (
        <ItemWrapper>
            <MenuItem onClick={onClick}>
                {label}
            </MenuItem>
        </ItemWrapper>
    )
}

const UserMenu = ({ anchorEl, setAnchorEl, user, history, isMyProfile, hasPermission, setShowArchiveUserPopup, setShowDeleteUserPopup, setShowEditUserPopup, setShowLogoutPopup }) => {
    return (
        <div className="profile-user-menu">
            {(isMyProfile || hasPermission(PERMISSIONS.WRITE_USER) || hasPermission(PERMISSIONS.WRITE_ARCHIVED_USER)) && (
                <KebabIcon className="kebab-icon" onClick={(e) => setAnchorEl(e.currentTarget)} />
            )}
            <Menu
                id="simple-menu"
                disableScrollLock={false}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                getContentAnchorEl={null}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={!!anchorEl}
                onClick={() => setAnchorEl(null)}
                keepMounted
            >
                <div>
                    <CustomMenuItem
                        label="Reset password"
                        isShowItem={isMyProfile}
                        onClick={() => history.push(`/reset-password/?email=${user.email}`)}
                    />
                    <CustomMenuItem
                        label="Edit Personal Information"
                        permissions={[PERMISSIONS.WRITE_USER]}
                        onClick={() => setShowEditUserPopup(true)}
                        accessForAdmin
                    />
                    <CustomMenuItem
                        label={!user.archived ? "Archive" : "Unarchive"}
                        isShowItem={!isMyProfile}
                        permissions={[PERMISSIONS.WRITE_ARCHIVED_USER]}
                        onClick={() => setShowArchiveUserPopup(true)}
                        accessForAdmin
                    />
                    <CustomMenuItem
                        label="Delete (permanently)"
                        isShowItem={!isMyProfile}
                        permissions={[PERMISSIONS.DELETE_USER]}
                        onClick={() => setShowDeleteUserPopup(true)}
                        accessForAdmin
                    />
                    <CustomMenuItem
                        label="Logout"
                        isShowItem={isMyProfile}
                        onClick={() => setShowLogoutPopup(true)}
                    />
                </div>
            </Menu>
        </div>
    )
}

export default UserMenu