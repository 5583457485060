import React, {useContext, useState} from "react"
import {PERMISSIONS} from "../../../../constants/permissions"
import Popup from "reactjs-popup"
import UpdateRolesPopup from "../Popups/UpdateRolesPopup"
import PenIcon from "@mui/icons-material/Create"
import {useSelector} from "react-redux"
import {ThemeContext} from "../../../../styles/theme-context"

const RolesCard = ({user, isMyProfile, setUserMessage, receiveUserInfo}) => {
    const [showUpdateRolesPopup, setShowUpdateRolesPopup] = useState(false)
    const {hasPermission} = useSelector((state) => state.profile)
    const theme = useContext(ThemeContext)

    const canEdit = hasPermission(PERMISSIONS.WRITE_ROLE)

    return (
        <>
            <div
                className={`
                    profile-right-roles 
                    ${canEdit ? "clickable" : ""} 
                    ${canEdit ? theme.profileCardHover : ""} 
                `}
                onClick={() => canEdit && setShowUpdateRolesPopup(true)}
            >
                <span className="t-h3 profile-font-leading">Roles</span>
                <div className="t-h3 profile-right-roles-content">
                    {user.roles.map((x) => x.role_name.toUpperCase()).join(", ")}
                </div>

                {canEdit  &&
                    <div className="profile-info-card-links">
                        <PenIcon onClick={() => setShowUpdateRolesPopup(true)} className="profile-info-card-pen-icon clickable"/>
                    </div>
                }
            </div>

            <Popup open={showUpdateRolesPopup} closeOnDocumentClick={false} onClose={() => setShowUpdateRolesPopup(false)} modal>
                {close =>
                    <UpdateRolesPopup
                        user={user}
                        receiveUserInfo={() => receiveUserInfo(user.id, isMyProfile, true)}
                        setUserMessage={setUserMessage}
                        close={close}
                    />
                }
            </Popup>
        </>
    )
}

export default RolesCard