import React, {useState} from "react"
import {Helmet} from "react-helmet"
import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"
import {PRODUCTS_SORT_OPTIONS} from "../../constants/other"
import "./index.scss"
import ShopProductCard from "./component/ShopProductCard"
import InfiniteScroll from "react-infinite-scroll-component"
import FilterPanel from "../../toolkits/FilterPanel"
import ShopHeader from "./component/ShopHeader"
import Protected from "../../components/Protected/Protected"
import {PERMISSIONS} from "../../constants/permissions"
import {EmptyResult} from "../../toolkits/EmptyResult/EmptyResult"
import DDFilter from "../../toolkits/DDFilter"
import { useProducts } from "./hooks/useProducts"
import StyledCircularProgress from "../../toolkits/CircularProgress/CircularProgress"

const Shop = () => {
    const [userMessage, setUserMessage] = useState(null)

    const {
        products,
        hasMore,
        empty,
        loadNextPage,
        categoryFilter,
        setCategoryFilter,
        sortBy,
        setSortBy,
        categoryStatuses,
    } = useProducts()

    return (
        <>
            <Helmet>
                <title> Products - Newsoft Inside </title>
            </Helmet>
            <div className="shop" id="shop-content">
                {userMessage && <AlertMessage setMessage={setUserMessage} message={userMessage.message} code={userMessage.code}/>}
                <div className="shop-header">
                    <ShopHeader/>
                    <div className="shop-header-filters">
                        <FilterPanel
                            title="Category"
                            options={categoryStatuses}
                            selectedOption={categoryFilter}
                            setOptionFilter={setCategoryFilter}
                        />
                        <DDFilter
                            title={"Sort by"}
                            defaultValue={{label: sortBy.label, value: sortBy.key}}
                            options={(Object.values(PRODUCTS_SORT_OPTIONS)).map((o) => ({label: o.label, value: o.key}))}
                            onChange={({ label, value }) => setSortBy({label, key: value})}
                        />
                    </div>
                </div>
                <InfiniteScroll
                    next={loadNextPage}
                    hasMore={hasMore}
                    loader={<StyledCircularProgress />}
                    dataLength={products.length}
                >
                    <div className="shop-content">
                        {products.map((product) =>
                            <Protected key={product.id}
                                permissions={product.public ? [PERMISSIONS.READ_SHOP] : [PERMISSIONS.WRITE_SHOP]}>
                                <ShopProductCard product={product} key={product.id}/>
                            </Protected>
                        )}
                        {empty && <div className="shop-content-empty-result"><EmptyResult/></div>}
                    </div>
                </InfiniteScroll>
            </div>
        </>
    )
}

export default Shop